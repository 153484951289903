import authService from "./auth";

class City {
    constructor() {
    }

    getCities() {
        return authService.get(
            `/geodata/companies/cities`,
        )
            .then((result) => {
                return result
            })
            .catch(e => new Promise.reject(e));
    }
}

export default new City()