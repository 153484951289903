import React from "react";
import { NavLink } from "react-router-dom";
import "./Tabs.scss";

const Tabs = () => {
    return (
        <div className={'tabs'}>
            <ul>
                <li>
                    <NavLink to='/analytics/rating'>Рейтинги и статистика</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/data-compare'>Сравнение данных</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/applications'>Сбор отзывов</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/qr'>QR-коды</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/branch'>Филиал</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/traffic'>Трафик</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/reports'>Генерация отчетов</NavLink>
                </li>

            </ul>
        </div>
    );
}

export default Tabs;
