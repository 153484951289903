import React, { useEffect } from "react";

const WikilectWidget = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://wikilect-widget.website.yandexcloud.net/wikilect-widget.js';
        script.type = 'module';
        script.async = false;
        document.head.appendChild(script);

        const addWidget = () => {
            const widgetHtml = `
                <wikilect-widget 
                    widget_id="47" 
                    host="wikilect.com" 
                    name="OK REVIEW" 
                    integration={"integration_id":45,"x_tenant":"okreview"} 
                    help_text="Здравствуйте! Какой у вас вопрос?" 
                    first_message="Привет! Я AI консультант. Готов вам помочь!" 
                    blank_links 
                    padding={"right":"42px","bottom":"27px"} 
                    theme="blue" 
                    ></wikilect-widget>              `;
            document.body.insertAdjacentHTML('beforeend', widgetHtml);
        };

        const checkBodyExists = setInterval(() => {
            if (document.body) {
                clearInterval(checkBodyExists);
                addWidget();
            }
        }, 10);

        return () => clearInterval(checkBodyExists);
    }, [])

    return null;
}

export default WikilectWidget;