import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import OkAnalytics from "../common/OkAnalytics";

import Header from "../Header";
import Footer from "../Footer";
import ApplicationFormAdd from "../pages/application/form/ApplicationFormAdd";
import ApplicationList from "../pages/application/list/ApplicationList";
import Develop from "../pages/Develop";
import ApplicationDetailCommon from "../pages/application/detail/ApplicationDetail";
import ReviewsCommon from "../pages/analytics/reviews/Reviews";
import ReviewDetail from "../pages/review/list/ReviewDetail";
import BranchCommon from "../pages/analytics/branch/Branch";
import DataCompare from "../pages/analytics/data-compare/DataCompare";
import ApplicationsCommon from "../pages/analytics/applications/Applications";
import ReviewListCommon from "../pages/review/ReviewListCommon";
import Reports from "../pages/analytics/reports/Reports";
import TrafficCommon from '../pages/analytics/traffic/Traffic';
import Integration from "../pages/integration/Integration";
import Dashboard from "../pages/dashboard/Dashboard";
import TelegramLogin from "components/pages/telegram/TelegramLogin";
import CompanyList from "../pages/company/list/CompanyList";
import ExternalAuth from "../pages/externalauth/ExternalAuth";
import Company from "../pages/company/edit/Company";
import Booklet from "../pages/integration/Booklet";
import QRCodeCommon from "../pages/analytics/qrcode/QRCode";
import Payload from "../pages/payload/Payload";
import TagsList from "../pages/tags/TagList";
import QrTransitionList from "../pages/qrtransition/QrTransitionList";
import QrTransitionDetail from "../pages/qrtransition/QrTransitionDetail";
import SettingsEmail from "../pages/settings/SettingsEmail";
import SettingsTelegram from "../pages/settings/SettingsTelegram";
import StorageDownloadFiles from "../pages/storage-files/StorageDownloadFiles";
import Page404 from "../pages/404/404";
import AccessDenied from "../pages/access-denied/AccessDenied";
import CompanyStatistics from "../pages/company/statistics/CompanyStatistics";

import './ErrorBoundary.scss';
import { Button } from "primereact/button";
import WikilectWidget from "../common/WikilectWidget";


const DEVELOP_EMAIL = 'test-user3@example.com';

const Internal = inject('userStore')(observer(({ userStore }) => {
    return (
        <>
            <ErrorBoundary>
                <Header/>
                <Preloader>
                    <Switch>
                        <Route exact path='/' component={Dashboard}/>
                        <Route path='/dashboard' component={Dashboard}/>}

{/*заявки создание список детальная*/}
                        <Route path='/application/add'>
                            <ProtectedRoute
                                element={ApplicationFormAdd}
                                fallback={AccessDenied}
                                requiredRole="createApplication"
                            />
                        </Route>
                        <Route path='/application/list'>
                            <ProtectedRoute
                                element={ApplicationList}
                                fallback={AccessDenied}
                                requiredRole="listApplications"
                            />
                        </Route>
                        <Route path='/application/detail/:id'>
                            <ProtectedRoute
                                element={ApplicationDetailCommon}
                                fallback={AccessDenied}
                                requiredRole="listApplications"
                            />
                        </Route>


{/*мониторинг список детальная*/}
                        <Route path='/monitoring/list'>
                            <ProtectedRoute
                                element={ReviewListCommon}
                                fallback={AccessDenied}
                                requiredRole="listReviews"
                            />
                        </Route>

                        <Route path='/monitoring/detail/:id'>
                            <ProtectedRoute
                                element={ReviewDetail}
                                fallback={AccessDenied}
                                requiredRole="listReviews"
                            />
                        </Route>

{/*аналитика */}

                        <Route path='/analytics/rating'>
                            <ProtectedRoute
                                element={ReviewsCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>

                        <Route path='/analytics/applications'>
                            <ProtectedRoute
                                element={ApplicationsCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>
                        <Route path='/analytics/qr'>
                            <ProtectedRoute
                                element={QRCodeCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>
                        <Route path='/analytics/branch'>
                            <ProtectedRoute
                                element={BranchCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>
                        <Route path='/analytics/branch/:id'>
                            <ProtectedRoute
                                element={BranchCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>

                        <Route path='/analytics/traffic'>
                            <ProtectedRoute
                                element={TrafficCommon}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>

                        <Route path='/analytics/data-compare'>
                            <ProtectedRoute
                                element={DataCompare}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>

                        <Route path='/analytics/reports'>
                            <ProtectedRoute
                                element={Reports}
                                fallback={AccessDenied}
                                requiredRole="analytics"
                            />
                        </Route>


                        <Route exact path='/integration' component={Integration}/>
                        <Route exact path='/integration/:attention' component={Integration}/>

                        <Route path='/qr/list' component={QrTransitionList}/>}
                        <Route path='/qr/detail/:id' component={QrTransitionDetail}/>}

                        <Route path='/settings/notifications/email' component={SettingsEmail}/>
                        <Route path='/settings/notifications/telegram' component={SettingsTelegram}/>
                        <Route path='/settings/data-uploads-and-reports' component={StorageDownloadFiles}/>

                        <Route path='/tags/list' component={TagsList}/>}

                        <Route path='/booklet/:id' component={Booklet}/>
                        <Route path='/booklet/:id/:title' component={Booklet}/>

                        <Route path='/settings' component={TelegramLogin}/>

                        <Route path='/externalauth/:auth_code' component={ExternalAuth}/>}


                        <Route path='/company-list'>
                            <ProtectedRoute
                                element={CompanyList}
                                fallback={AccessDenied}
                                requiredRole="manageGeodata"
                            />
                        </Route>

                        <Route path='/company-statistics'>
                            <ProtectedRoute
                                element={CompanyStatistics}
                                fallback={AccessDenied}
                                requiredRole="manageGeodata"
                            />
                        </Route>

                        <Route path='/company/:id'>
                            <ProtectedRoute
                                element={Company}
                                fallback={AccessDenied}
                                requiredRole="manageGeodata"
                            />
                        </Route>



                        {userStore.profile.user.email === DEVELOP_EMAIL &&
                            <Route path='/dev' component={Develop}/>}
                        {userStore.profile.user.email === DEVELOP_EMAIL &&
                            <Route path='/payload' component={Payload}/>}

                        <Route path='/404' component={Page404}/>}

                        <Route path='/access-denied' component={AccessDenied}/>

                        <Redirect to='/404'/>
                    </Switch>

                </Preloader>
                <Footer/>
            </ErrorBoundary>

            <WikilectWidget/>
        </>

    )
}));

const Preloader = ({ children }) => {
    return (
        <div className={'content'}>
            {children}
        </div>
    );
};

class ErrorBoundary extends Component {
    state = {
        error: null,
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        const { error } = this.state;

        if (error) {

            return (
                <>
                    <div/>
                    <div className={'error-container'}>
                        <div className={'error'}>
                            <div className={'error__text'}>Ошибка</div>
                            <div className={'error__message'}>
                                <p>При загрузке страницы произошел сбой.</p>
                                <p>Попробуйте повторить действие или обратитесь в поддержу</p>
                            </div>
                            <div className={'error__button'}>
                                <Button label='Вернуться на главную' onClick={() => {
                                    window.location.href = '/';
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div/>
                </>
            )
        }
        return this.props.children;
    }

}

const ProtectedRoute = inject('userStore')(observer(({
                                                         userStore,
                                                         element: Component,
                                                         fallback: Fallback,
                                                         requiredRole
                                                     }) => {
    const { profile } = userStore;

    return profile?.allowed[requiredRole] === true ? <Component/> : <Fallback/>;
}));


export default withRouter(OkAnalytics(Internal));


