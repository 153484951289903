import React, { useEffect, useState } from "react";
import okAnalyticsService from "../../../../services/okAnalytics";
import { inject, observer } from "mobx-react";
import { Button } from "primereact/button";
import cn from "classnames";
import Tabs from "../Tabs";
import image from '../../../../images/reports/image95.png';
import image1 from '../../../../images/reports/image96.png';
import image2 from '../../../../images/reports/image97.png';
import "./Reports.scss";


const Reports = inject('analyticsStore', 'userStore', 'uiStore')(observer(({ analyticsStore, userStore, uiStore }) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_reports', target: 'page', event: 'open',
        });
    }, []);

    return (<>

            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs/>
                </div>

                <div className={cn('analytics-table', 'table', 'reports-table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'}/>}
                        <div className={cn('table')}>
                            <div className={cn('items-container')}>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#AE200D'}}>Обзор отзывов за месяц</h1>
                                        <img src={image} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#104115'}}>Обзор отзывов за месяц</h1>
                                        <img src={image1} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{}}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#01575E'}}>Обзор отзывов за месяц</h1>
                                        <img src={image2} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#EF8D78'}}>Обзор отзывов за месяц</h1>
                                        <img src={image} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#01575E'}}>Обзор отзывов за месяц</h1>
                                        <img src={image1} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{}}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#EF8D78'}}>Обзор отзывов за месяц</h1>
                                        <img src={image} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{}}
                                        />
                                    </div>
                                </div>
                                <div className={cn('item')}>
                                    <div className={cn('item__header')}>
                                        <h5>Отчет</h5>
                                        <h1 style={{'color': '#104115'}}>Обзор отзывов за месяц</h1>
                                        <img src={image2} alt="" width={200} height={235}/>
                                    </div>
                                    <div className={cn('item__content')}>
                                        Мониторинг тональности по площадкам за месяц,
                                        динамика отзывов по месяцам,
                                        динамика тональности по месяцам, негативные отзывы по темам, позитивные отзывы по темам
                                    </div>
                                    <div className={cn('item__footer')}>
                                        <Button
                                            label='Скачать'
                                            className={cn('p-button-outlined')}
                                            onClick={()=>{
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
}))

export default Reports